import React from 'react'
import {graphql} from 'gatsby'
import * as styles from '../styles/AppliedSciences.module.scss'
import ASHeader from '../components/AppliedSciences/ASHeader/ASHeader'
import ASContent from '../components/AppliedSciences/ASContent/ASContent'
import MarketoContactForm from '../components/ContactForm/MarketoContactForm'
import Layout from '../components/Layout/Layout'
import Seo from '../components/Seo/Seo'

export const query = graphql`
query appliedSciencePages {
  allSanityAppliedSciences {
    nodes {
      showElement
      slug {
        current
      }
      browserTitle
      seoData {
        title
        description
        image {
          asset {
            url
          }
        }
      }
      marketoFormID
      headerImageBlock {
        header
        pageCategoryName
        _rawText
        image {
          asset {
            url
          }
        }
        altText
        button {
          buttonText
          buttonData
          buttonContext
          buttonFile {
            asset {
              url
              title
            }
          }
        }
        announcement {
          showElement
          title
          button {
            buttonText
            buttonData
            buttonContext
            buttonFile {
              asset {
                url
                title
              }
            }
          }
          image {
            asset {
              url
            }
          }
          altText
        }
      }
      content {
        ... on SanityYellowBlock {
          _type
          showElement
          blockContext
          _rawTextContent
          footnoteText
          _rawFootnote
          _rawBoldText
        }
        ... on SanityStatBlock {
          _type
          showElement
          title
          statTitle
          stats {
            showElement
            icon {
              asset {
                url
              }
            }
            numeric
            descriptor
            subtext
          }
          image {
            asset {
              url
            }
          }
          altText
          _rawDescription
        }
        ... on SanityColoredBlock {
          _type
          showElement
          title
          _rawDescription
          button {
            buttonText
            buttonData
            buttonContext
            buttonFile {
              asset {
                url
              }
            }
          }
        }
        ... on SanityBillboard {
          _type
          showElement
          title
          _rawDescription
          maxColumns
          _rawFootnote
          billboardType
          backgroundImage {
            asset {
              url
              gatsbyImageData
            }
          }
          altText
          posts {
            ... on SanityPost {
              _type
              showElement
              title
              lineBreak
              _rawDescription
              icon {
                asset {
                  url
                }
              }
              altText
              button {
                buttonText
                buttonData
                buttonContext
                buttonFile {
                  asset {
                    url
                  }
                }
              }
            }
            ... on SanityWhitePaper {
              _type
              showElement
              title
              _rawDescription
              button {
                buttonText
                buttonData
                buttonContext
                buttonFile {
                  asset {
                    url
                  }
                }
              }
            }
          }
        }
        ... on SanityAddInfo {
          _type
          showElement
          headerImage {
            asset {
              url
              gatsbyImageData
            }
          }
          caseStudies {
            showElement
            title
            bodyText
            backgroundImage {
              asset {
                gatsbyImageData
              }
            }
            link
          }
          infoArr {
            showElement
            title
            description
            ctaTitle
            ctaRoute
            ctaFile {
              asset {
                url
              }
            }
          }
        }
        ... on SanityHeroBlock {
          _type
          showElement
          title
          _rawDescription
          image {
            asset {                       
              url
              gatsbyImageData
            }
          }
          altText
          buttonOptions {
            buttonText
            buttonData
            buttonContext
            buttonFile {
              asset {
                url
              }
            }
          }
        }
        ... on SanityQuoteSection {
          _type
          showElement
          image {
            asset {
              url
            }
          }
          title
          quotes {
            showElement
            _rawQuote
            author
          }
        }
        ... on SanityPartnerSection {
          _type
          showElement
          title
          columns
          partnerLogos {
            asset {
              url
            }
          }
        }
        ... on SanityContentBlock {
          _type
          showElement
          logo {
            asset {
              url
            }
          }
          altLogoText
          title
          _rawSubtitle
          _type
          popOut
          image {
            asset {
              url
              gatsbyImageData
            }
          }
          altText
          blocks {
            ... on SanityLinkBlock {
              _type
              showElement
              title
              image {
                asset {
                  url
                  gatsbyImageData
                }
              }
              altText
              imageLink
              _rawDescription
              button {
                buttonText
                buttonData
                buttonContext
                buttonFile {
                  asset {
                    url
                  }
                }
              }
            }
            ... on SanityFeatureList {
              _type
              showElement
              maxColumns
              features {
                title
                showElement
                _rawDescription
                variant
                button {
                  buttonText
                  buttonData
                  buttonContext
                  buttonFile {
                    asset {
                      url
                    }
                  }
                }
                footnoteText
                _rawFootnote
              }
              title
              _rawDescription
            }
            ... on SanityNewsList {
              _type
              showElement
              newsArticles {
                showElement
                title
                slug {
                  current
                }
                _rawSubtitle
                alignment
                _rawArticle
                image {
                  asset {
                    url
                  }
                }
                altText
                button {
                  buttonText
                  buttonData
                  buttonContext
                  buttonFile {
                    asset {
                      url
                    }
                  }
                }
                _rawFootnote
              }
            }
            ... on SanityYellowBlock {
              _type
              showElement
              _rawTextContent
              footnoteText
              _rawFootnote
              _rawBoldText
              blockContext
            }
            ... on SanityBillboard {
              _type
              showElement
              title
              _rawDescription
              _rawFootnote
              maxColumns
              billboardType
              posts {
                ... on SanityPost {
                  _type
                  showElement
                  title
                  lineBreak
                  icon {
                    asset {
                      url
                    }
                  }
                  _rawDescription
                  button {
                    buttonText
                    buttonData
                    buttonContext
                    buttonFile {
                      asset {
                        url
                      }
                    }
                  }
                }
                ... on SanityWhitePaper {
                  showElement
                  title
                  _rawDescription
                  button {
                    buttonText
                    buttonData
                    buttonContext
                    buttonFile {
                      asset {
                        url
                      }
                    }
                  }
                  _type
                }
              }
              maxColumns
              backgroundImage {
                asset {
                  url
                }
              }
              altText
              _rawDescription
            }
            ... on SanityImageBlock {
              _type
              showElement
              header
              image {
                asset {
                  url
                }
              }
              altText
              imageLink
              mobileImage {
                asset {
                  url
                }
              }
              _rawCaption
            }
            ... on SanityTeamList {
              _type
              showElement
              title
              _rawDescription
              team
            }
            ... on SanityItemPopoverSection {
              _type
              showElement
              title
              _rawDescription
              items {
                title
                subtitle
                showElement
                _rawDescription
                altText
                image {
                  asset {
                    url
                  }
                }
              }
            }
          }
        }
      }
      marketoFormHeader
      marketoFormSubheader
    }
  }
  allSanityTeamMember(sort: {fields: orderRank}) {
    nodes {
      showElement
      name
      orderRank
      profile {
        asset {
          url
        }
      }
      altText
      associatedTeams
      position
      degrees
      _rawBiography
      showcase {
        caseName
        _rawDescription
      }
    }
  }
}
  `

const AppliedSciencesTemplate = props => {
    const { data, pageContext } = props;
    const {appliedSciencesSlug} = pageContext;
    const pageData = data.allSanityAppliedSciences.nodes.find(appSci => 
      appSci.slug.current === appliedSciencesSlug.current);
    let appSciData = pageData;
    appSciData.content.teamMembers = data.allSanityTeamMember.nodes;
    // special case for header image position on team/leadership page
    // ToDo - consider adding position option to Sanity
    const headerClassname = appliedSciencesSlug.current.includes('/leadership') ? 'positionRight' : null

    
    return (
        <>
        <Layout>
          <main>
            <ASHeader data={appSciData.headerImageBlock} headerClassname={headerClassname} themeContext={'appliedSciences'}/>
            <ASContent data={appSciData.content} pageContext={appliedSciencesSlug.current} themeContext={'appliedSciences'}/>
            {appSciData.marketoFormID ?
              <div className={styles.formContainer}>
                <MarketoContactForm formID={"mktoForm_" + appSciData.marketoFormID} formCode={appSciData.marketoFormID} variant={'appSci'}
                heading={appSciData.marketoFormHeader || ""}
                subheading={appSciData.marketoFormSubheader || ""} />
              </div>
            :
            null
            }
          </main>
        </Layout>
        </>
    )
}

export default AppliedSciencesTemplate

export const Head = (props) => {
  const { data, pageContext } = props;
  const {appliedSciencesSlug} = pageContext;
  const pageData = data.allSanityAppliedSciences.nodes.find(appSci => 
    appSci.slug.current === appliedSciencesSlug.current);
  return (
    <Seo title={pageData.browserTitle} seo={pageData.seoData} />
  )
}